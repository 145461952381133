/* rc-dock elements customised for abyss fabric */
.abyssdock :global(.dock-ink-bar) {
  background-color: #000000;
}
.abyssdock :global(.dock-tab-active:hover) ~ :global(.dock-ink-bar) {
  background-color: #1976d2;
}
.abyssdock :global(.dock-tab-active) {
  color: #000000;
}
.abyssdock :global(.dock-tab-active:hover) {
  color: #1976d2;
}
.abyssdock :global(.dock-tab) {
  text-transform: uppercase;
  height: auto;
  min-height: 30px;
}
.abyssdock :global(.dock-tab) > :global(.dock-tab-btn) {
  padding: 8px 16px;
}
